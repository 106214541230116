import React, { useEffect, useRef } from 'react';
import YouTube from 'react-youtube';
import type { VideoClip } from '../types';

interface Props {
  clip: VideoClip;
  onClipEnd: () => void;
}

export default function VideoPlayer({ clip, onClipEnd }: Props) {
  const playerRef = useRef<any>(null);
  const checkInterval = useRef<number>();

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(clip.startTime);
    }
    return () => {
      if (checkInterval.current) {
        window.clearInterval(checkInterval.current);
      }
    };
  }, [clip]);

  const onReady = (event: any) => {
    playerRef.current = event.target;
    event.target.seekTo(clip.startTime);
    checkInterval.current = window.setInterval(() => {
      const currentTime = event.target.getCurrentTime();
      if (currentTime >= clip.endTime) {
        onClipEnd();
      }
    }, 1000);
  };

  return (
    <div className="aspect-video w-full bg-black">
      <YouTube
        videoId={clip.videoId}
        opts={{
          width: '100%',
          height: '100%',
          playerVars: {
            autoplay: 1,
            start: clip.startTime,
          },
        }}
        onReady={onReady}
        className="w-full h-full"
      />
    </div>
  );
}