export interface SharedContent {
  title?: string | null;
  text?: string | null;
  url?: string | null;
}

export function parseYoutubeVideoId(url: string): string | null {
  try {
    const videoUrl = new URL(url);
    if (videoUrl.hostname.includes('youtube.com')) {
      return videoUrl.searchParams.get('v');
    } else if (videoUrl.hostname === 'youtu.be') {
      return videoUrl.pathname.slice(1);
    }
  } catch (e) {
    console.error('Invalid URL:', e);
  }
  return null;
}

export function processSharedContent(content: SharedContent) {
  const { url } = content;
  
  if (url) {
    const videoId = parseYoutubeVideoId(url);
    if (videoId) {
      return {
        type: 'youtube' as const,
        videoId,
      };
    }
  }
  // Function to handle file sharing
  function handleFileSharing(content: SharedContent) {
    const { url } = content;
    
    if (url) {
      const fileExtension = getFileExtension(url);
      if (fileExtension) {
        return {
          type: 'file' as const,
          fileExtension,
        };
      }
    }
    return null;
  }

  // Helper function to get file extension from URL
  function getFileExtension(url: string): string | null {
    try {
      const fileUrl = new URL(url);
      const pathname = fileUrl.pathname;
      const extension = pathname.split('.').pop();
      return extension ? extension : null;
    } catch (e) {
      console.error('Invalid URL:', e);
    }
    return null;
  }

  // Integrate file sharing handling into processSharedContent
  const fileShareResult = handleFileSharing(content);
  if (fileShareResult) {
    return fileShareResult;
  }
  return null;
} 