import { KBarProvider, KBarPortal, KBarSearch, KBarResults, useKBar, useMatches } from 'kbar';
import { Command } from 'lucide-react';

const OpenCommandButton = () => {
  const { query } = useKBar();
  return (
    <button 
      onClick={() => query.toggle()}
      className="inline-flex items-center justify-center h-8 w-8 text-gray-500 rounded-lg hover:bg-gray-100"
      title="Command Palette (⌘K)"
    >
      <Command className="h-4 w-4" />
    </button>
  );
};

const CommandPalette = () => {
  const { query } = useKBar();
  return (
    <KBarPortal>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50" onClick={() => query.toggle()}>
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()} >
          <KBarSearch
            className="w-full p-4 border-b border-gray-200 text-lg focus:outline-none"
            placeholder="Type a command or search..."
          />
          <RenderResults />
        </div>
      </div>
    </KBarPortal>
  );
};

const KbarTest = () => {
  const actions = [
    { id: 'home', name: 'Home', perform: () => (window.location.href = '/') },
    { id: 'dashboard', name: 'Dashboard', perform: () => (window.location.href = '/dashboard') },
  ];

  return (
    <KBarProvider actions={actions}>
      <OpenCommandButton />
      <CommandPalette />
    </KBarProvider>
  );
};

const RenderResults = () => {
  const { results } = useMatches();
  return (
    <KBarResults
      items={results}
      onRender={({ item }) => (
        <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer">{item.name}</div>
      )}
    />
  );
};


export default KbarTest