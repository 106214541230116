import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import VideoPlayer from '../components/VideoPlayer';
import PlaylistTab from '../components/PlaylistTab';
import { samplePlaylists } from '../data/sampleData';
import type { VideoClip } from '../types';

function SampleClips() {
  const { videoId } = useParams();
  const [activePlaylistIndex, setActivePlaylistIndex] = useState(0);
  const [activeClip, setActiveClip] = useState<VideoClip>(() => {
    if (videoId) {
      // Create a temporary clip for the shared video
      return {
        id: `shared-${videoId}`,
        title: 'Shared Video',
        videoId: videoId,
        startTime: 0,
        endTime: undefined // Play until the end
      };
    }
    return samplePlaylists[0].clips[0];
  });

  const handleClipEnd = () => {
    for (let i = 0; i < samplePlaylists.length; i++) {
      const currentPlaylist = samplePlaylists[i];
      const currentIndex = currentPlaylist.clips.findIndex(
        (clip) => clip.id === activeClip.id
      );
      
      if (currentIndex !== -1 && currentIndex < currentPlaylist.clips.length - 1) {
        setActiveClip(currentPlaylist.clips[currentIndex + 1]);
        return;
      }
    }
  };

  const handlePrevPlaylist = () => {
    if (activePlaylistIndex > 0) {
      setActivePlaylistIndex(activePlaylistIndex - 1);
    }
  };

  const handleNextPlaylist = () => {
    if (activePlaylistIndex < samplePlaylists.length - 1) {
      setActivePlaylistIndex(activePlaylistIndex + 1);
    }
  };

  return (
    <div className="md:grid md:grid-cols-[1fr,400px] md:min-h-[calc(100vh-64px)]">
      <div className="sticky top-16 z-10 bg-black md:h-[calc(100vh-64px)]">
        <VideoPlayer clip={activeClip} onClipEnd={handleClipEnd} />
      </div>
      
      <div className="md:h-[calc(100vh-64px)] md:overflow-y-auto">
        <div className="max-w-4xl mx-auto px-4 py-6 md:px-2 md:py-4">
          <div className="bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-between p-4 border-b">
              <button
                onClick={handlePrevPlaylist}
                disabled={activePlaylistIndex === 0}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              <h2 className="text-xl font-semibold">
                {samplePlaylists[activePlaylistIndex].title}
              </h2>
              <button
                onClick={handleNextPlaylist}
                disabled={activePlaylistIndex === samplePlaylists.length - 1}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
            
            <PlaylistTab
              clips={samplePlaylists[activePlaylistIndex].clips}
              activeClip={activeClip}
              onClipSelect={setActiveClip}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SampleClips; 