import { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Clip } from '../types';

interface SearchResult {
  id: string;
  content: string;
  video_id: string;
  similarity: number;
  start_time: number;
  end_time: number;
  metadata: {
    title: string;
    thumbnail: string;
    description: string;
  };
}

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL!,
  import.meta.env.VITE_SUPABASE_ANON_KEY!
);

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    setIsLoading(true);
    setError(null);


    console.log('VITE_SUPABASE_URL:', import.meta.env.VITE_SUPABASE_URL);

    try {
      // Get the current session
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        throw new Error('Please sign in to continue');
      }

      // Call the Supabase Edge Function
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/search-content`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ query: searchQuery }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Search failed');
      }

      const data = await response.json();

      console.log(data)

      setResults(data.results);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      console.error('Search error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Search Video Content</h1>
        
        {/* Search Input */}
        <div className="flex gap-2">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            placeholder="Ask anything about the videos..."
            className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            onClick={handleSearch}
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-blue-300 hover:bg-blue-600 transition-colors"
          >
            {isLoading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {/* Results */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {results && results.map((result) => (
          <SearchResultCard key={result.id} result={result} />
        ))}
      </div>

      {/* No Results Message */}
      {!isLoading && results && results.length === 0 && searchQuery && (
        <div className="text-center text-gray-500 mt-8">
          No results found for "{searchQuery}"
        </div>
      )}
    </div>
  );
};

interface SearchResultCardProps {
  result: SearchResult;
}

const SearchResultCard = ({ result }: SearchResultCardProps) => {
  const { video_id, similarity, start_time, end_time, metadata, content } = result;

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div className="border rounded-lg p-4 hover:shadow-lg transition-shadow bg-white">
      <div className="aspect-video mb-2 relative group">
        <img
          src={metadata.thumbnail}
          alt={metadata.title}
          className="w-full h-full object-cover rounded"
        />
        <a
          href={`https://youtube.com/watch?v=${video_id}&t=${Math.floor(start_time)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all"
        >
          <span className="text-white bg-blue-500 px-3 py-1 rounded">Watch on YouTube</span>
        </a>
      </div>
      <h3 className="font-semibold mb-1 line-clamp-2">{metadata.title}</h3>
      <p className="text-sm text-gray-600 mb-2 line-clamp-2">{content}</p>
      <div className="flex justify-between items-center text-sm">
        <span className="text-gray-500">
          {formatTime(start_time)} - {formatTime(end_time)}
        </span>
        <span className="text-blue-500">
          Match: {(similarity * 100).toFixed(1)}%
        </span>
      </div>
    </div>
  );
};

export default SearchPage; 