import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Combobox } from '@headlessui/react';

interface POI {
  id: number;
  lat: number;
  lon: number;
  distance?: number;
  bearing?: number;
  tags?: {
    name?: string;
    [key: string]: string | undefined;
  };
}

interface Location {
  lat: number;
  lon: number;
  city?: string;
  town?: string;
  suburb?: string;
}

interface PlaceStoryProps {
  latitude?: number;
  longitude?: number;
}

const PlaceStory: React.FC<PlaceStoryProps> = ({ latitude: initialLat, longitude: initialLon }) => {
  const [pois, setPois] = useState<POI[]>([]);
  const [loading, setLoading] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);
  const [categoryQuery, setCategoryQuery] = useState('');
  const [permissionState, setPermissionState] = useState<PermissionState | null>(null);

  const calculateDistanceAndBearing = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;

    // Calculate bearing
    const y = Math.sin(dLon) * Math.cos(lat2 * Math.PI / 180);
    const x = Math.cos(lat1 * Math.PI / 180) * Math.sin(lat2 * Math.PI / 180) -
              Math.sin(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.cos(dLon);
    const bearing = (Math.atan2(y, x) * 180 / Math.PI + 360) % 360;

    return { distance, bearing };
  };

  const fetchLocationDetails = async (lat: number, lon: number) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      const { address } = response.data;
      setLocation({
        lat,
        lon,
        city: address.city || address.town || address.village,
        town: address.suburb || address.neighbourhood
      });
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const fetchPOIs = async (lat: number, lon: number) => {
    const overpassUrl = 'https://overpass-api.de/api/interpreter';
    
    const query = `
      [out:json];
      (
        node["amenity"](around:1000, ${lat}, ${lon});
        node["tourism"](around:1000, ${lat}, ${lon});
        node["shop"](around:1000, ${lat}, ${lon});
      );
      out body;
    `;

    try {
      setLoading(true);
      const response = await axios.post(overpassUrl, query, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      // Process POIs with distance and bearing
      const processedPois = response.data.elements.map((poi: POI) => {
        const { distance, bearing } = calculateDistanceAndBearing(lat, lon, poi.lat, poi.lon);
        return { ...poi, distance, bearing };
      });

      // Extract unique categories
      const categories = new Set<string>();
      processedPois.forEach((poi: POI) => {
        if (poi.tags) {
          Object.keys(poi.tags).forEach(tag => categories.add(tag));
        }
      });
      setAvailableCategories(Array.from(categories));
      setPois(processedPois);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const initializeLocation = () => {
    if (initialLat && initialLon) {
      fetchLocationDetails(initialLat, initialLon);
      fetchPOIs(initialLat, initialLon);
    } else if (navigator.geolocation) {
      setGpsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocationDetails(latitude, longitude);
          fetchPOIs(latitude, longitude);
          setGpsLoading(false);
        },
        (error) => {
          setError(`Error getting location: ${error.message}`);
          setGpsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    initializeLocation();
  }, [initialLat, initialLon]);


  useEffect(() => {
    if (!navigator.permissions) {
      setPermissionState('unsupported');
      return;
    }

    navigator.permissions.query({ name: 'geolocation' })
      .then(permissionStatus => {
        setPermissionState(permissionStatus.state);
        permissionStatus.onchange = () => {
          setPermissionState(permissionStatus.state);
        };
      })
      .catch(error => {
        console.error('Permission check failed:', error);
        setPermissionState('error');
      });
  }, []);


  const renderPermissionMessage = () => {
    if (!permissionState || permissionState === 'granted') return null;

    const messages = {
      denied: 'Location access was denied. Please enable location services to use all features.',
      prompt: 'Please allow location access to use all features.',
      unsupported: 'Location services are not supported in your browser.',
      error: 'There was an error checking location permissions.'
    };

    return (
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          {renderPermissionMessage()}
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              {messages[permissionState as keyof typeof messages]}
            </p>
          </div>
        </div>
      </div>
    );
  };


  const filteredPois = selectedCategories.length > 0
    ? pois.filter(poi => 
        poi.tags && Object.keys(poi.tags).some(tag => selectedCategories.includes(tag)))
    : pois;

  const filteredCategories = categoryQuery === ''
    ? availableCategories
    : availableCategories.filter((category) =>
        category.toLowerCase().includes(categoryQuery.toLowerCase())
      );

  const renderCategoriesFilter = () => (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-2">Filter by Category</h2>
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedCategories.map(category => (
          <span
            key={category}
            className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-500 text-white"
          >
            {category}
            <button
              onClick={() => setSelectedCategories(prev => prev.filter(c => c !== category))}
              className="ml-2 focus:outline-none"
            >
              ×
            </button>
          </span>
        ))}
      </div>
      
      <Combobox
        value={selectedCategories}
        onChange={setSelectedCategories}
        multiple
      >
        <div className="relative">
          <Combobox.Input
            className="w-full px-4 py-2 border rounded-lg"
            placeholder="Search categories..."
            onChange={(event) => setCategoryQuery(event.target.value)}
          />
          <Combobox.Options className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
            {filteredCategories.map((category) => (
              <Combobox.Option
                key={category}
                value={category}
                className={({ active }) =>
                  `${active ? 'bg-blue-500 text-white' : 'text-gray-900'}
                   cursor-pointer select-none relative py-2 pl-10 pr-4`
                }
              >
                {({ selected, active }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {category}
                    </span>
                    {selected && (
                      <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? 'text-white' : 'text-blue-500'
                      }`}>
                        ✓
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );

  const renderRadar = () => (
    <div className="mb-6">
      <div className="relative w-64 h-64 mx-auto border-2 border-gray-300 rounded-full bg-cover bg-center" style={{ backgroundImage: `url('https://tile.openstreetmap.org/${location?.lat},${location?.lon}.png')` }}>
        {/* Map features */}
        <div className="absolute inset-0">
          {/* North indicator */}
          <div className="absolute top-2 left-1/2 transform -translate-x-1/2 text-sm font-bold">N</div>
          
          {/* Cardinal directions */}
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-sm">S</div>
          <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-sm">W</div>
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm">E</div>
          
          {/* Compass lines */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full h-px bg-gray-300 transform rotate-45"></div>
            <div className="w-full h-px bg-gray-300 transform -rotate-45"></div>
          </div>
        </div>

        {/* POI points */}
        {filteredPois.map((poi) => {
          const angle = (poi.bearing || 0) * (Math.PI / 180);
          const distance = (poi.distance || 0);
          const maxDistance = 1;
          const scale = Math.min(distance / maxDistance, 1);
          const x = 32 + Math.sin(angle) * (32 * scale);
          const y = 32 - Math.cos(angle) * (32 * scale);

          return (
            <div
              key={poi.id}
              className="absolute w-2 h-2 bg-red-500 rounded-full transform -translate-x-1 -translate-y-1 
                         hover:w-3 hover:h-3 hover:bg-red-600 transition-all duration-200"
              style={{
                left: `${x}%`,
                top: `${y}%`,
              }}
              title={`${poi.tags?.name || 'Unnamed'} (${distance.toFixed(2)}km)`}
            />
          );
        })}
      </div>
    </div>
  );

  if (gpsLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-gray-300 h-12 w-12"></div>
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {location && (
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-gray-800">
            {location.city && `Exploring ${location.city}`}
            {location.town && ` - ${location.town}`}
          </h1>
          <p className="text-gray-600">
            Current coordinates: {location.lat.toFixed(6)}, {location.lon.toFixed(6)}
          </p>
        </div>
      )}

      {renderCategoriesFilter()}
      {renderRadar()}

      {/* POI List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredPois.map((poi) => (
          <div 
            key={poi.id}
            className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition duration-200"
          >
            <h2 className="text-xl font-semibold mb-2">{poi.tags?.name || 'Unnamed POI'}</h2>
            <p className="text-gray-600 mb-2">
              Type: {Object.keys(poi.tags || {}).join(', ') || 'Unknown'}
            </p>
            <p className="text-gray-600">
              Distance: {poi.distance?.toFixed(2)}km
            </p>
            <p className="text-gray-600">
              Bearing: {poi.bearing?.toFixed(1)}°
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaceStory;
