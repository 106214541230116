import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import SampleClips from './pages/SampleClips';
import ShareHandler from './components/ShareHandler';
import SignInPage from './pages/SignInPage';

//for lazy
import { Suspense, lazy, useEffect, useState } from 'react';
import KbarTest from './pages/KbarTest';
import PlaceStory from './pages/PlaceStory';
import { supabase } from './services/supabaseClient';
import { Session } from '@supabase/supabase-js';

const LazyThreeComponent = lazy(() => import('./pages/ThreelazyTest'));

// Protected route wrapper component
function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return <Navigate to="/signin" replace />;
  }

  return <>{children}</>;
}

function RootHandler() {
  const urlParams = new URLSearchParams(window.location.search);
  const sharedUrl = urlParams.get('url');
  
  if (sharedUrl) {
    const searchString = window.location.search;
    return <Navigate to={`/share${searchString}`} replace />;
  }
  
  return <HomePage />;
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <Routes>
          {/* Public routes */}
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/" element={<RootHandler />} />
          <Route path="/share" element={<ShareHandler />} />

          {/* Protected routes */}
          <Route path="/search" element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          } />
          <Route path="/clips" element={
            <ProtectedRoute>
              <SampleClips />
            </ProtectedRoute>
          } />
          <Route path="/clips/:videoId" element={
            <ProtectedRoute>
              <SampleClips />
            </ProtectedRoute>
          } />
          <Route path="/gps" element={
            <ProtectedRoute>
              <PlaceStory />
            </ProtectedRoute>
          } />
          <Route path="/three" element={
            <ProtectedRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <LazyThreeComponent />
              </Suspense>
            </ProtectedRoute>
          } />
          <Route path="/whiteboard" element={
            <ProtectedRoute>
              <KbarTest />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
}


// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN || "", // Retrieve DSN from environment variables
//   integrations: [
// //commented to lazy load later
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),

//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });



// const {Replay} = await import  ('@sentry/browser')



export default App;

