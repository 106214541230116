import { Playlist } from '../types';

export const samplePlaylists: Playlist[] = [
  {
    id: '1',
    title: 'Best Tech Reviews',
    clips: [
      {
        id: '1',
        videoId: 'dQw4w9WgXcQ',
        title: 'Amazing Product Review',
        description: 'Detailed review of the latest gadget',
        startTime: 0,
        endTime: 180,
        thumbnail: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f',
        rating: 4.5
      },
      {
        id: '2',
        videoId: 'zcrY0qayWF4',
        title: 'Tech Comparison',
        description: 'Comparing the top devices',
        startTime: 180,
        endTime: 360,
        thumbnail: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b',
        rating: 4.8
      }
    ]
  },
  {
    id: '2',
    title: 'Coding Tutorials',
    clips: [
      {
        id: '3',
        videoId: 'dQw4w9WgXcQ',
        title: 'React Basics',
        description: 'Learn React fundamentals',
        startTime: 0,
        endTime: 240,
        thumbnail: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee',
        rating: 4.7
      }
    ]
  }
];