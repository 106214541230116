import { Link, useLocation } from 'react-router-dom';
import { Search } from 'lucide-react';
import KbarTest from '../pages/KbarTest';

export const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <span className="text-xl font-bold text-gray-800">ClipFinder</span>
            </Link>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavLink to="/" current={location.pathname === "/"}>
                Home
              </NavLink>
              <NavLink to="/clips" current={location.pathname === "/clips"}>
                Sample Clips
              </NavLink> 
              <NavLink to="/gps" current={location.pathname === "/gps"}>
                places arount
              </NavLink>
            </div>
          </div>
          <div className="flex items-center gap-2 sm:gap-4">
            <KbarTest />
            <Link
              to="/search"
              className="inline-flex items-center px-3 py-1.5 sm:px-4 sm:py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              <Search className="h-4 w-4 mr-2" />
              <span className="hidden sm:inline">Search</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({ to, children, current }: { to: string; children: React.ReactNode; current: boolean }) => {
  return (
    <Link
      to={to}
      className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
        current
          ? 'border-blue-500 text-gray-900'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
      }`}
    >
      {children}
    </Link>
  );
}; 