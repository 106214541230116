import React from 'react';
import { Star } from 'lucide-react';
import type { VideoClip } from '../types';

interface Props {
  clip: VideoClip;
  isActive: boolean;
  onClick: () => void;
}

export default function ClipCard({ clip, isActive, onClick }: Props) {
  return (
    <div
      className={`flex gap-4 p-4 cursor-pointer transition-colors ${
        isActive ? 'bg-blue-50' : 'hover:bg-gray-50'
      }`}
      onClick={onClick}
    >
      <img
        src={clip.thumbnail}
        alt={clip.title}
        className="w-32 h-20 object-cover rounded"
      />
      <div className="flex-1">
        <h3 className="font-semibold text-gray-900">{clip.title}</h3>
        <p className="text-sm text-gray-600 line-clamp-2">{clip.description}</p>
        <div className="flex items-center gap-2 mt-1">
          <span className="text-sm text-gray-500">
            {Math.floor((clip.endTime - clip.startTime) / 60)}:
            {String((clip.endTime - clip.startTime) % 60).padStart(2, '0')}
          </span>
          <div className="flex items-center gap-1">
            <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
            <span className="text-sm text-gray-700">{clip.rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
}