import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { processSharedContent } from '../services/shareHandler';

function ShareHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sharedContent = {
      title: searchParams.get("title"),
      text: searchParams.get("text"),
      url: searchParams.get("url"),
    };

    const result = processSharedContent(sharedContent);
    
    if (result?.type === 'youtube') {
      navigate(`/clips/${result.videoId}`);
    } else {
      navigate('/clips'); // Fallback to regular clips page if content isn't supported
    }
  }, [location, navigate]);

  return <div>Processing shared content...</div>;
}

export default ShareHandler; 