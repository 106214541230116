import React from 'react';
import type { VideoClip } from '../types';
import ClipCard from './ClipCard';

interface Props {
  clips: VideoClip[];
  activeClip: VideoClip;
  onClipSelect: (clip: VideoClip) => void;
}

export default function PlaylistTab({ clips, activeClip, onClipSelect }: Props) {
  return (
    <div className="flex flex-col divide-y">
      {clips.map((clip) => (
        <ClipCard
          key={clip.id}
          clip={clip}
          isActive={clip.id === activeClip.id}
          onClick={() => onClipSelect(clip)}
        />
      ))}
    </div>
  );
}